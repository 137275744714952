import AuthService from '@/services/authService'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  userProfile: {
    name: '',
    fullname: '',
    username: ''
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateProfile (state, profile) {
    state.userProfile = profile
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile().then((profile) => {
      if(profile.status === 'success') {
        commit('updateProfile', profile.data)
      }
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
