import 'regenerator-runtime/runtime'

import api from "@/services/apiService"

class AuthService {

  /**
   * เข้าสู่ระบบ
   * @param  {Object} data {username: '', password: ''}
   * @return {Object}      Promise resolve/reject
   */
  login(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'login', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ออกจากระบบ backend
   * @return {Promise}
   */
  logout() {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'logout')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ข้อมูลส่วนตัว
   * @return {Object} ข้อมูลส่วนตัว
   */
  getProfile() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'profile')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * เปลี่ยนรหัสผ่าน
   * @return {Object}
   */
  changePassword(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'profile/password', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ยอดคงเหลือในบัญชี
   */
  getBalance() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account/balance')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * แก้ไขข้อมูลส่วนตัว
   * @param  {Object} data
   * @return {Object}
   */
  updateProfile(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'profile', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  /**
   * ข้อมูลบัญชี
   * @return {Object} {balance, member, online}
   */
  getAccount() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'account')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

}

export default new AuthService()
