import axios from 'axios'
import Auth from '@/helpers/auth'

axios.interceptors.request.use(async function(config) {
  const token = await Auth.getToken()
  config.withCredentials = true
  config.headers.Authorization = `Bearer ${token}`
  return config;
}, function(error) {
  // Do something with request error
  return Promise.reject(error.response.data);
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error)
    if(error.response.status === 401)
      return Auth.logout()

    return Promise.reject(error.response?.data)
  }
);

export default axios
