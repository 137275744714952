import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './router'
import AppConfig from '@/configs/app.config.js'

Vue.use(AclInstaller)

export default new AclCreate({
  initial: 'public',
  notfound: {
    path: '/404',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isAdmin: new AclRule('admin').generate(),
    isManager: new AclRule('manager').generate(),
    isBranch: new AclRule('branch').generate(),
    isPublic: new AclRule('public').or('admin').or('manager').or('branch').generate()
  },
  middleware: async acl => {
    const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
    const Role = LoggedInData?.role
    // console.log('Role', LoggedInData, Role)
    if(Role === 'Admin') {
      acl.change('admin')
    }else
    if(Role === 'Manager') {
      acl.change('manager')
    }else
    if(Role === 'Branch'){
      acl.change('branch')
    }else{
      acl.change('public')
    }
  }
})
