import 'regenerator-runtime/runtime'

import AppConfig from '@/configs/app.config.js'
import AuthService from '@/services/authService'
import router from '@/router'

class Auth {

  /**
   * บันทึก cookie การเข้าสู่ระบบ
   * @param  {Object} data {token: '', refreshToken: ''}
   * @return {Boolean}      Promise
   */
  async saveLogin(data) {
    const LoggedInData = JSON.stringify(data)
    await localStorage.setItem(AppConfig.key.LoggedIn, LoggedInData)
    return true
  }

  /**
   * ออกจากระบบ frontend และส่งไปหน้า login
   */
  logout() {
    AuthService.logout()
    .then(async (response)=>{
      console.log(response)
    })
    .finally(async ()=>{
      await localStorage.removeItem(AppConfig.key.LoggedIn)
      router.push({name: 'Login'})
    })
  }

  /**
   * token ที่เก็บไว้ frontend มาติดต่อกับ backend
   * @return {[String]} token or null
   */
  async getToken() {
    try {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return LoggedInData?.token || null
    }
    catch(e) {
      console.log(e)
      return null
    }
  }

}

export default new Auth()
