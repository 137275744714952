const AppConfig = {
  key: {
    LoggedIn: 'loggedInLottoReport'
  },
  accrual: {
    // itemId: '611fbc92449ecd3e282a7e90'
    itemId: '612320e59e0bf2000e5fb1a3'
  },
  salary: {
    itemIds: ['6135e810164479000eb8ea3c']
  }
}

export default AppConfig;
