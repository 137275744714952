import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard'),
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/admin',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'AdminDashboard',
          component: () => import('@/views/admin/Dashboard'),
          redirect: { name: 'AdminReportDaily' },
          meta: {
            rule: 'isAdmin'
          }
        },
        {
          path: 'net-profit',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'AdminNetProfit',
              component: () => import('@/views/admin/report/NetProfit'),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: 'balance',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'AdminBalance',
              component: () => import('@/views/admin/report/Balance'),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: 'accrual',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'AdminAccrualRecord',
              component: () => import('@/views/admin/report/AccrualRecord'),
              meta: {
                rule: 'isAdmin'
              }
            }
          ]
        },
        {
          path: 'report',
          name: 'AdminReport',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'daily',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AdminReportDaily',
                  component: () => import('@/views/admin/report/Daily'),
                  meta: {
                    rule: 'isAdmin'
                  }
                },
                {
                  path: ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                  name: 'AdminReportDate',
                  component: () => import('@/views/admin/report/Daily'),
                  meta: {
                    rule: 'isAdmin'
                  }
                },
              ]
            },
            {
              path: 'date',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                  component: { render (c) { return c('router-view') } },
                  children: [
                    {
                      path: ':accountId',
                      name: 'AdminReportDateBranch',
                      component: () => import('@/views/admin/report/DateBranch'),
                      meta: {
                        rule: 'isAdmin'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'summary',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AdminReportSummary',
                  component: () => import('@/views/manager/report/Summary'),
                  meta: {
                    rule: 'isAdmin'
                  }
                }
              ]
            },
            {
              path: 'agent',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AdminReportAgent',
                  component: () => import('@/views/branch/report/Agent'),
                  meta: {
                    rule: 'isAdmin'
                  }
                }
              ]
            },
            {
              path: 'market',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AdminReportMarket',
                  component: () => import('@/views/branch/report/Market'),
                  meta: {
                    rule: 'isAdmin'
                  }
                }
              ]
            },
            {
              path: 'other',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AdminReportOther',
                  component: () => import('@/views/branch/report/Other'),
                  meta: {
                    rule: 'isAdmin'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'setting',
          name: 'AdminSetting',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'branch',
              name: 'AdminSettingBranch',
              component: () => import('@/views/admin/setting/Branch'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'website',
              name: 'AdminSettingWebsite',
              component: () => import('@/views/admin/setting/Website'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'market-group',
              name: 'AdminSettingMarketGroup',
              component: () => import('@/views/admin/setting/MarketGroup'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'market',
              name: 'AdminSettingMarket',
              component: () => import('@/views/admin/setting/Market'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'item',
              name: 'AdminSettingOtherItem',
              component: () => import('@/views/admin/setting/OtherItem'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'assistant',
              name: 'AdminSettingAssistant',
              component: () => import('@/views/admin/setting/Assistant'),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: 'manager',
              name: 'AdminSettingManager',
              component: () => import('@/views/admin/setting/Manager'),
              meta: {
                rule: 'isAdmin'
              }
            },
          ]
        }
      ]
    },
    {
      path: '/manager',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'ManagerDashboard',
          component: { render (c) { return c('router-view') } },
          redirect: { name: 'ManagerReportDaily' },
          meta: {
            rule: 'isManager'
          }
        },
        {
          path: 'accrual',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'BranchAccrualRecord',
              component: () => import('@/views/branch/AccrualRecord'),
              meta: {
                rule: 'isManager'
              }
            }
          ]
        },
        {
          path: 'report',
          name: 'ManagerReport',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'daily',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'ManagerReportDaily',
                  component: () => import('@/views/manager/report/Daily'),
                  meta: {
                    rule: 'isManager'
                  }
                },
                {
                  path: ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                  name: 'ManagerReportDate',
                  component: () => import('@/views/manager/report/Daily'),
                  meta: {
                    rule: 'isManager'
                  }
                },
              ]
            },
            {
              path: 'date',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: ':date([0-9]{4}-[0-9]{2}-[0-9]{2})',
                  component: { render (c) { return c('router-view') } },
                  children: [
                    {
                      path: ':accountId',
                      name: 'ManagerReportDateBranch',
                      component: () => import('@/views/branch/DailyRecord'),
                      meta: {
                        rule: 'isManager'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'summary',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'ManagerReportSummary',
                  component: () => import('@/views/manager/report/Summary'),
                  meta: {
                    rule: 'isManager'
                  }
                }
              ]
            },
            {
              path: 'agent',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'ManagerReportAgent',
                  component: () => import('@/views/branch/report/Agent'),
                  meta: {
                    rule: 'isManager'
                  }
                }
              ]
            },
            {
              path: 'market',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'ManagerReportMarket',
                  component: () => import('@/views/branch/report/Market'),
                  meta: {
                    rule: 'isManager'
                  }
                }
              ]
            },
            {
              path: 'other',
              component: { render (c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'ManagerReportOther',
                  component: () => import('@/views/branch/report/Other'),
                  meta: {
                    rule: 'isManager'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'setting',
          name: 'ManagerSetting',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'branch',
              name: 'ManagerSettingBranch',
              component: () => import('@/views/admin/setting/Branch'),
              meta: {
                rule: 'isManager'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/branch',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'BranchDashboard',
          component: () => import('@/views/branch/Dashboard'),
          meta: {
            rule: 'isBranch'
          }
        },
        {
          path: 'daily-record',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'BranchDailyRecord',
              component: () => import('@/views/branch/DailyRecord'),
              meta: {
                rule: 'isBranch'
              }
            },
            {
              path: ':date',
              name: 'BranchDateRecord',
              component: () => import('@/views/branch/DailyRecord'),
              meta: {
                rule: 'isBranch'
              }
            }
          ]
        },
        {
          path: 'accrual',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'BranchAccrualRecord',
              component: () => import('@/views/branch/AccrualRecord'),
              meta: {
                rule: 'isBranch'
              }
            }
          ]
        },
        {
          path: 'report',
          name: 'BranchReport',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'daily',
              name: 'BranchReportDaily',
              component: () => import('@/views/branch/report/Daily'),
              meta: {
                rule: 'isBranch'
              }
            },
            {
              path: 'agent',
              name: 'BranchReportAgent',
              component: () => import('@/views/branch/report/Agent'),
              meta: {
                rule: 'isBranch'
              }
            },
            {
              path: 'market',
              name: 'BranchReportMarket',
              component: () => import('@/views/branch/report/Market'),
              meta: {
                rule: 'isBranch'
              }
            },
            {
              path: 'other',
              name: 'BranchReportOther',
              component: () => import('@/views/branch/report/Other'),
              meta: {
                rule: 'isBranch'
              }
            }
          ]
        },
        {
          path: 'setting',
          name: 'BranchSetting',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: 'agent',
              name: 'BranchSettingAgent',
              component: () => import('@/views/branch/setting/Agent'),
              meta: {
                rule: 'isBranch'
              }
            },
            // {
            //   path: 'manager',
            //   name: 'BranchSettingManager',
            //   component: () => import('@/views/branch/setting/Manager'),
            //   meta: {
            //     rule: 'isBranch'
            //   }
            // },
            {
              path: 'member',
              name: 'BranchSettingMember',
              component: () => import('@/views/branch/setting/Member'),
              meta: {
                rule: 'isBranch'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404,
      meta: {
        rule: 'isPublic'
      }
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500,
      meta: {
        rule: 'isPublic'
      }
    }
  ]
}

// router.beforeEach((to, from, next) => {
//   const publicPages = ['Login'];
//   const authRequired = !publicPages.includes(to.name);
//   const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

//   console.log('router.beforeEach')

//   if (authRequired && !loggedIn) {
//     next({name: 'Login'});
//   } else {
//     next();
//   }
// });

export default router
